@import "../../styles/variables.scss";

.iconContainer {
  display: inline-flex;
  margin: 8px;
  padding: 8px;
  background-color: #CCCCCC;
  cursor: pointer;
  border-radius: 5px;
  
  svg {
    color: white;
    width: 48px !important;
    height: 48px !important;
  }

  &.active {
    background: $red-deepviz !important;
  }
}