@import "../../styles/variables.scss";

div.container {
  position: relative;
  cursor: pointer !important;
}

div.iconContainer {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;

  &.selected {
    background: $dark-gray;
    svg {
      color: $athens-gray !important;
    }

    & + span {
      background: $dark-gray;
      color: $athens-gray !important;
    }
  }

  &:hover + span {
    transition: display .3s;
    display: inherit;
  }

  &:hover svg {
    color: #d3d3d4;
  }

  svg {
    margin: 0 auto;
    display: block;
    width: auto !important;
    font-size: 36px;
    padding: 6px;
    margin: auto;
    color: $dark-gray;
  }
}

span.labelContainer {
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  background: $athens-gray;
  left: 50px;
  width: max-content;
  display: none;
  
  p {
    margin: 0;
    padding: 0 10px;
  }

  &:hover {
    transition: display .3s;
    display: inherit;
  }
}