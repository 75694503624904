@import '../../styles/variables.scss';

.fullTitle {
  padding: .75em;
  color: #333;
  background-color: #f5f5f5;
  margin: 0;
  vertical-align: middle;
  transition: box-shadow 1s ease-in;

  &.big {
    margin-bottom: .5em;
    font-size: 14px;
  }
}

.fullTitleIcon {
  font-size: 1.25em !important;
  vertical-align: top;
  margin-right: .2em;
  vertical-align: sub;

  &.danger {
    color: $red-deepviz;
  }

  &.success {
    color: $green-bootstrap-success;
  }
}

.enlighted {
  box-shadow: -5px 0px 0px 0px $red-deepviz;
}