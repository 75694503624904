@import "../../styles/variables.scss";

.customTooltip {
  background: $athens-gray;
  border: 1px solid rgba(0,0,0,.125);
  padding: 6px;
  font-size: 13px;
  border-radius: 5px;

  & > p {
    margin: 0;
  }
}

.cardTitle {
  text-align: center;
  background: $gray-bootstrap-secondary;
  color: white;
  padding: .35rem 0;
}

.bigCounter {
  margin: 0;
  line-height: 75px;
  text-align: center;
  font-size: 48px;
  font-weight: 700;
}

.processing {
  color: $yellow-bootstrap-warning;
}

.unsupported {
  color: $black-bootstrap-dark;
}

.pending {
  color: $gray-bootstrap-secondary;
}

.rowGraph {
  margin-bottom: 1rem;
}