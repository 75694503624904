@import '../../styles/variables.scss';

span.StringBadge {
  margin-left: .35rem !important;
  font-size: 11px !important;
  text-transform: uppercase;
  margin-left: .2rem;
  border: 1px solid $gray-bootstrap-secondary;
  color: $gray-bootstrap-secondary;
  background: white;
  cursor: pointer;
}

span.extracted {
  color: $green-bootstrap-success;
  border-color: $green-bootstrap-success;
}