@import '../../styles/variables.scss';

.title {
  background-color: $gray-bootstrap-secondary;
  color: white;
  font-weight: bold;
  max-width: 170px;
  width: 170px;
}

.sectionTitle {
  font-weight: bold;
  margin-right: 1rem;
}