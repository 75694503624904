@import '../../styles/variables.scss';

.loading {
  color: $red-deepviz;
  text-align: center;
  margin: 4rem;

  & > * {
    animation-duration: 1.75s !important;
  }
}