$red-deepviz:               #af3437;

$green-bootstrap-success:   #28a745;
$yellow-bootstrap-warning:  #ffc107;
$black-bootstrap-dark:      #343a40;
$gray-bootstrap-secondary:  #6c757d;
$teal-bootstrap-info:       #17a2b8;
$red-bootstrap-danger:      #dc3545;

$gray-base:                 #e7e7e7;
$gray-medium:               #ababab;
$athens-gray:               #f8f9fa;
$dark-gray:                 #8f8f8f;

/* NEBULA */
$wildSand:                  #F4F5F5;
$heather:                   #B8C2CE;
$fun-blue:                  #2678df;
$shuttleGray:               #5C6066;
$persian-red:               #c93631;
$mercury:                   #E5E5E5;
$fern:                      #57b573;

:export {
  graphColor01: #E53935;
  graphColor02: #AD1457;
  graphColor03: #7B1FA2;
  graphColor04: #5E35B1;
  graphColor05: #3949AB;
  graphColor06: #1E88E5;
  graphColor07: #29B6F6;
  graphColor08: #00ACC1;
  graphColor09: #00897B;
  graphColor10: #4CAF50;
  graphColor11: #9CCC65;
  graphColor12: #9E9D24;
  graphColor13: #FBC02D;
  graphColor14: #FF8F00;
  graphColor15: #D84315;
  graphColor16: #6D4C41;
  graphColor17: #757575;
  graphColor18: #546E7A;
}

:export {
  dangerRed:      $red-bootstrap-danger;
  successGreen:   $green-bootstrap-success;
  warningYellow:  $yellow-bootstrap-warning;
  mediumGray:     $gray-bootstrap-secondary;
  darkGray:       $black-bootstrap-dark;
}

/* METHODS */
:export {
  getMethod:    #5b87a9;
  postMethod:   #60874f;
  putMethod:    #df9133;
  deleteMethod: #9f4b4c;
}

/* STATUS */
:export {
  defaultStatus:                #dddddd;
  informationalResponseStatus:  #3949AB;
  successStatus:                #60874f;
  redirectionStatus:            #df9133;
  clientErrorStatus:            #9537d1;
  serverErrorStatus:            #9f4b4c;
}