@import "./variables.scss";
@import "./tables.scss";
@import "./badges.scss";
@import "./common.scss";

html,
body {
  height:100%
}

body {
  background: white;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .toastify-content--info { border-color: #yourColor; }
// .toastify-content--success {border-color: #yourColor; }
// .toastify-content--warning {border-color: #yourColor;  }
// :global(.toastify-content--error) {border-color: $red-deepviz; }

// .toastify__progress--info { background-color: #yourColor; }
// .toastify__progress--success { background-color: #yourColor; }
// .toastify__progress--warning { background-color: #yourColor; }
:global(.Toastify__toast--error) { background-color: $red-deepviz; }
:global(.Toastify__toast--success) { background-color: $green-bootstrap-success; }

:global(.recharts-cartesian-axis-tick) {
  font-size: 11px;
}