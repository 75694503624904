@import '../../styles/variables.scss';

.platformStatus {
  font-size: 19px;
  margin: 0 .1rem;
}

.success {
  color: $green-bootstrap-success;
}

.failed {
  color: $red-deepviz;
}

.notStarted {
  color: $black-bootstrap-dark;
}