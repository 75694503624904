@import "./variables.scss";

:global(.processing) {
  background-color: $yellow-bootstrap-warning !important;
  color: $black-bootstrap-dark !important;
  font-size: 100% !important;
}

:global(.pending) {
  background-color: $gray-bootstrap-secondary !important;
  font-size: 100% !important;
}

:global(.success), :global(.clean), :global(.completed) {
  background-color: $green-bootstrap-success !important;
  font-size: 100% !important;
}

:global(.failed), :global(.malicious) {
  background-color: $red-deepviz !important;
  font-size: 100% !important;
}

:global(.notstarted), :global(.idle), :global(.notfound)  {
  background-color: $black-bootstrap-dark !important;
  font-size: 100% !important;
}

:global(.unknown) {
  background-color: $gray-bootstrap-secondary !important;
  font-size: 100% !important;
}

:global(.unsupported) {
  background-color: $black-bootstrap-dark !important;
  font-size: 100% !important;
}