@import "../../styles/variables.scss";

div.toolbar {
  min-height: calc(100vh - 59px);
  background: $athens-gray;
  margin-top: -1rem;
  width: 50px;
  text-align: center;
  z-index: 10;

  hr {
    margin: 0 auto;
    width: 90%;
    color: rgba(0,0,0, 0.07);
  }
}