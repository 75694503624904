.errorTitle {
  font-weight: bold;
  font-size: 18px;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

.errorBody {
  font-size: 13px;
}