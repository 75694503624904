@import '../../styles/variables.scss';

.NoDataAvailable {
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    text-align: center;
    margin: 0;
    font-style: italic;
    color: $gray-bootstrap-secondary;
    font-size: 14px;
  }
}