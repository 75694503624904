@import '../../styles/variables.scss';

.submissionIcon {
  width: 1.5em;
}

.groupIcon {
  font-size: 1.2em;
  width: 1.2em;
}

.extraIcon {
  font-size: 1.2em;
  width: 1.2em;
  cursor: pointer;
}

.extraInfo {
  font-family: 'Courier New', Courier, monospace;
}

.selectFullHeight {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='18' viewBox='2 -2 18 18' width='16' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-x: 100% !important;
  cursor: pointer;
}

.actionIcon {
  cursor: pointer;
  margin-right: .5rem;
}

.doubleFilter {
  display: block;
  width: 100%;
}

.pleaseUseFilters {
  text-align: center;
  font-style: italic;
  color: $gray-medium;
  font-size: 14px;
  padding-bottom: 20px;
}

.timePickerButton {
  background: transparent;
  border: 0px;
}

.timePickerButton svg {
  margin: 0;
  vertical-align: middle;
}

.resetRangeDate {
  vertical-align: middle;
  font-size: 8px;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid $gray-bootstrap-secondary;
  padding: 2px 4px;;
  border-radius: 4px;
}

.rangeDate {
  margin: 0;
  text-align: left;
  font-size: 11px;
}

.rangeDate:not(:last-child) {
  margin-top: 8px;
}