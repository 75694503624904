@import "../../styles/variables.scss";

$platformContainerHeight: 50px;

div.container {
  position: relative;
  cursor: pointer !important;
}

div.platformContainer {
  height: $platformContainerHeight;
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover :last-child {
    transition: display .3s;
    display: flex;
  }

  svg {
    margin: 0 auto;
    display: block;
    width: auto !important;
    font-size: 36px;
    padding: 6px;
    margin: auto;
    color: rgba(0,0,0,.5);
  }
}

span.platformLabel {
  font-size: 9px;
  letter-spacing: -.5px;
}

span.labelContainer {
  position: absolute;
  top: 0;
  height: 50px;
  line-height: 50px;
  background: $athens-gray;
  left: 50px;
  width: max-content;
  display: none;
  
  p {
    margin: 0;
    padding: 0 10px;
  }

  &:hover {
    transition: display .3s;
    display: flex;
  }
}

div.otherPlatform {
  display: flex;
  padding: 0 20px;
  zoom: .75;
  cursor: pointer;

  &:hover {
    background: darken($athens-gray, 5%);
  }
  svg:hover {
    color: rgba(0,0,0,.5);
  }
}