@import "../../styles/variables.scss";

div.loggedOutContainer {
  text-align: center;
  margin-top: 5em;

  p {
    color: $gray-medium;
  }
}

p.loggedOutIcon {
  text-align: center;
  font-weight: 700;
  font-size: 64px;
  svg {
    color: $red-deepviz;
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
    -webkit-animation: wink 0.5s alternate infinite; /* Safari 4+ */
    -moz-animation:    wink 0.5s alternate infinite; /* Fx 5+ */
    -o-animation:      wink 0.5s alternate infinite; /* Opera 12+ */
    animation:         wink 0.5s alternate infinite; /* IE 10+, Fx 29+ */
    -webkit-transform-origin: 50% 100%;
    -moz-transform-origin: 50% 100%;
    -o-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-animation-timing-function: ease; /* Chrome, Safari, Opera */
    animation-timing-function: ease;
    }
}

@-webkit-keyframes wink {
  0% {
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
  }
  100% {
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -o-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);}
} 
  
@keyframes wink {
  0% {
    -webkit-transform: rotate(-40deg);
    -moz-transform: rotate(-40deg);
    -o-transform: rotate(-40deg);
    -ms-transform: rotate(-40deg);
    transform: rotate(-40deg);
  }
  100% {
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -o-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
  }
}