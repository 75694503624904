@import "../../styles/variables.scss";

.cardDownload {
  max-width: 300px;
  margin-left: 30px !important;
  margin-right: 30px !important;
  
  svg {
    color: $gray-bootstrap-secondary;
    margin: auto;
    font-size: 64px;
    margin-bottom: 20px;
  }

  button {
    max-height: 38px;

    svg {
      color: white !important;
      font-size: inherit !important;
      margin-top: 4px;
    }
  }
}

.warning {
  color: $yellow-bootstrap-warning !important;
}