:global(.containsLongWords) {
  word-break: break-all;
}

:global(.hasToBeSingleLine) {
  white-space: nowrap;
}

:global(.cursorPointer) {
  cursor: pointer;
}

:global(.cursorNotAllowed) {
  cursor: not-allowed;
}

:global(.fakeLink) {
  font-size: 12px;
  padding: 0;
  color: inherit;
}