@import "../../styles/variables.scss";

.categoryContainer {
  display: inline-block;
  padding: 0 20px 20px 20px !important;
  border: 2px solid #e5e5e5;
  margin: 0 10px 25px 10px !important;
  border-radius: 10px;
  background: white;

  legend {
    display: block;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 600;
    border: 0;
    width: auto;
    padding: 0 10px;
  }
}