.platformContainer {
  position: relative;
  display: inline-block;
  text-align: center;
}

.platformBit {
  position: absolute;
  right: 0;
  top: -4px;
  font-size: 9px;
  background-color: #333;
  color: white;
  padding: 2px;
  border-radius: 20px;
  width: 16px;
  height: 16px;
}

.platformName {
  display: block;
    font-size: 10px;
    color: #333;
}

.icon {
  color: #0078d7;
}