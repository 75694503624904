@import "../../../styles/variables.scss";

.title {
  background: $dark-gray;
  color: white;
  width: 170px;
  max-width: 170px;
  vertical-align: middle !important;
}

.tableHeader {
  background: $dark-gray;
  color: white;
}

.triggeredRulesRecap {
  background: $wildSand;
  padding: 1em;
  line-height: 24px;

  span {
    margin-right: .5em;
    font-size: 12px !important;
  }
}

.high {
  color: $red-deepviz;
}

.medium {
  color: $yellow-bootstrap-warning;
}

.low {
  color: $gray-bootstrap-secondary;
}

.ruleVerbose {
  margin-left: .4em;
}

.filter {
  height: 1.8em;
  cursor: pointer;
  
  &:hover {
    filter: brightness(120%);
  }
}

.filterLabel {
  margin-left: .4em;
  font-size: 12px !important;
}

.filterCounter {
  width: 2.5em;
  text-align: center !important;
  font-size: 12px !important;
}

.overviewPlatformSelector {
  margin: 1em 0;

  button {
    margin-right: 1em;
  }
}

.rulesContainer {
  > div {
    max-height: 450px;
    overflow-y: auto;
  }
}

.screenshotImage {
  display: block;
  margin: 1.5rem auto;
}

:global(.button-back.disabled), :global(.button-forward.disabled) {
  display: none;
}

.logsContainer {
  display: block;
  margin: 1rem auto;
  width: 90%;
  height: 650px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 12px;
}

.downloadDroppedFile {
  width: 150px;
  text-align: center;

  svg {
    cursor: pointer;
    font-size: 18px;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}

.sectionSubtitle {
  font-weight: bold;
  font-size: 1.1em;
}

.dynamicTabs {
  padding: 0.3rem 0.75rem;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  color: $black-bootstrap-dark;

  &.active {
    font-weight: bold;
  }
}

.infoTabs {
  color: $red-deepviz !important;
  font-weight: bold;
}

.dynamicTabsContainer {
  padding: .5rem;
  border: 1px solid #dee2e6;
  border-top: 0;
}

textarea.stringsContainer {
  width: 100%;
  border: 0;
  font-family: courier new;
  font-size: 13px;
  max-height: 450px;
  height: 450px;
  overflow-y: auto;
  resize: none;

  &:focus {
    border: 0;
    outline: none !important;
  }
}

.badgeSeparator {
  border: 0;
  border-left: 1px solid $gray-bootstrap-secondary;
  border-radius: 0;
  background: transparent;
  margin: 0 0 0 .35rem;
  padding: 0;
  vertical-align: middle;
}

.extractedStringTitle {
  color: $red-deepviz;
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

.extractedStringElement {
  margin: 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 13px;
}

.parsedStringContainer {
  height: 450px;
  max-height: 450px;
  overflow: auto;
}

.injectedThreadActivitiesNav {
  border-color: $red-deepviz $red-deepviz #fff !important;
}

.injectedThreadActivitiesNavTabs {
  border-bottom: 1px solid $red-deepviz !important;
}

.injectedThreadActivitiesContent {
  border-color: #fff $red-deepviz $red-deepviz $red-deepviz !important;
  border-bottom: 1px solid $red-deepviz !important;
}

.downloadPCAP {
  margin: 10px auto 50px auto;
  text-align: center;

  svg {
    margin-left: 5px;
  }
}

div.networkMapContainer {
  width: 100%;
  overscroll-behavior: contain;
}

.extractedString {
  cursor: pointer;
  color: $green-bootstrap-success;
  margin-left: .5em;
}