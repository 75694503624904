@import "../../styles/variables.scss";

div.notFoundContainer {
  text-align: center;
  margin-top: 5em;

  p {
    color: $gray-medium;
  }
}

p.notFoundCode {
  text-align: center;
  font-weight: 700;
  font-size: 64px;

  svg {
    color: $red-deepviz;
  }
}
