@import '../../styles/variables.scss';
div.reportContainer {
  display: flex;
}

div.sectionContainer {
  font-size: 12px;
  position: relative;
  max-width: calc(100% - 50px); //100% - toolbar width
}

.noResultFound {
  text-align: center;
  font-size: 20px;
  color: $black-bootstrap-dark;
  background-color: $athens-gray;
  padding: 24px;
  border-radius: 10px;
  max-width: 400px;
  margin: 5rem auto;

  span {
    font-size: 48px;
    vertical-align: middle;
    margin-right: 1rem;
  }
}