.navbarLogo {
  width: 32px;
  height: 32px;
  margin-right: .5rem;
}

.disabled {
  pointer-events: none;
  color: rgba(0,0,0,.3) !important;
}

.serviceNameContainer {
  display: inline-block;
  vertical-align: middle;
  margin: 0 .5rem 0 0;

  span:first-child {
    display: block;
    font-size: 1.2rem;
  }
}

.serviceVersion {
  display: block;
  margin-top: -3px;
  font-size: .6rem;
  text-align: right;
  line-height: 3px;
}