@import "../../styles/variables.scss";

.progressCircle {
  display: inline-block;
  position: relative;
  text-align: center;
}

.circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 0.3s ease-in;
  stroke: #ccc;
}

.progress {
  transform: rotate(-90deg);
  transform-origin: center;
  stroke: $fern;
}

.percentageText {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.check {
  color: $fern;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
