@import "../../styles/variables.scss";

.container {
  display: flex;
  min-height: 270px;
  padding: 4px 0;
}

.dropTargetContainer,
.informationContainer {
  display: column;
}

.dropTargetContainer {
  text-align: center;
}

.dropTarget {
  background-color: $wildSand;
  height: 200px;
  margin-bottom: 20px;
  width: 250px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;

  .topAndBottomBorders,
  .leftAndRightBorders {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  .leftAndRightBorders:before,
  .leftAndRightBorders:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-image: linear-gradient(to top, $heather 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 2px 24px;
    background-repeat: repeat-y;
  }

  .topAndBottomBorders:before,
  .topAndBottomBorders:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, $heather 51%, rgba(255, 255, 255, 0) 0%);
    background-size: 24px 2px;
    background-repeat: repeat-x;
  }

  .leftAndRightBorders:after {
    left: 0;
    right: 2px;
  }

  .leftAndRightBorders:before {
    right: 0;
  }

  .topAndBottomBorders:before {
    background-position: bottom;
    bottom: 0;
  }

  .topAndBottomBorders:after {
    background-position: top;
    top: 0;
  }
}

.dropTargetContent {
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  .uploadIcon {
    color: $fun-blue;
    font-size: 70px;
    opacity: 0.3;

    &.noFileIcon {
      color: $shuttleGray;
    }
  }

  .errorIcon {
    color: $persian-red;
  }

  .uploadText {
    display: inline-block;
    font-size: 14px;
    margin: 5px 0 0 0;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 0.7;
    padding: 0 10px;
  }
}

input[type="file"].input {
  display: none;
}

.informationContainer {
  border-left: 1px solid $mercury;
  padding: 0 20px;
  margin-left: 20px;
}

.fileInfoLabel {
  color: $shuttleGray;
  display: block;
  // font-family: $font-family-light;
  font-size: 13px;
  margin-bottom: 15px;

  &.fileTypeLabel {
    // font-family: $font-family-medium;
    margin-bottom: 0;
    margin-top: 5px;
    text-transform: uppercase;
  }
}

.fileReqSection,
.fileActionSection {
  padding: 0 10px 5px 10px;
  max-width: 300px;
}

.fileActionSection {
  .buttonPane {
    height: 40px;

    button {
      margin: 0 5px 0 0;
    }
  }
}

.fileSectionSeparator {
  height: 0;
  border-bottom: 1px solid $mercury;
  margin: 5px auto 15px auto;
}

.fileType {
  text-transform: uppercase;
}

.fileSizeReq + .fileSizeReq {
  margin-left: 10px;
}

.progressContainer {
  float: left;
  margin-right: 10px;
}
