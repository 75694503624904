@import '../../styles/variables.scss';

button.uploadButton {
  padding: 1em 2em;
  margin: auto;
}

div.sandboxHomeContainer {
  margin-top: 4em;
  text-align: center;
}

.homeContent {
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #dee2e6;
  border-width: 0 1px 1px 1px;
  border-style: solid;
}

$active-shadow: 0px -3px 0px $red-bootstrap-danger inset;

.tabSelector {
  background-color: $athens-gray !important;
  color: $gray-bootstrap-secondary !important;
  border: 0 !important;
  border-top: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
  padding: 20px !important;
  
  &:hover {
    box-shadow: $active-shadow;
  }
}

.activeTab {
  font-weight: 700;
  box-shadow: $active-shadow;
}

.firstChild {
  border-left: 1px solid #dee2e6 !important;
}

.lastChild {
  border-right: 1px solid #dee2e6 !important;
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-top: 1rem;
  font-size: 11px;

  p:nth-child(2) {
    color: $dark-gray;
  }
}