@import "../../styles/variables.scss";

.vpnContainer {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.containerLabel {
  font-size: 11px;
  font-weight: normal;
  background-color: #ddd;
  color: dimgrey;
  padding: .2em .6em .3em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.KO {
  border-color: $red-deepviz !important;
}

.vpnIconContainer {
  color: darkgrey;
  background: #f5f5f5;
  border-radius: 100px;
  border: 1px dashed #ddd;
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 80px;
  margin: auto auto 4px auto;
  position: relative;
}

.vpnIconStatus {
  position: absolute;
  font-size: 22px !important;
  right: 10px;
  bottom: 10px;
  color: $green-bootstrap-success;
}

.vpnIconStatusDanger {
  color: $red-deepviz;
}

.vpnContainerLabel {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
}