@import "../../styles/variables.scss";

.shutoff {
  color: $red-deepviz;
}

.run {
  color: $green-bootstrap-success;
}

.updating {
  color: $yellow-bootstrap-warning;
}

.statusContainer {
  margin-bottom: 3rem;

  & > p:first-child {
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $gray-bootstrap-secondary;
    color: white;
    padding: .5em 1em;
    display: table;
    margin: 0 auto 1em;
  }
}

.nodeName {
  background: #f5f5f5;
  color: #333;
  padding: .5em;
  display: inline-block;
  font-size: 14px;
}

.nodeProperties {
  margin-bottom: .5rem;

  p {
    margin-bottom: .3em;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
  }

  li {
    font-size: 14px;
  }
}